<!-- 添加通讯录弹窗 -->
<template>
  <div>
    <el-dialog
      width="90%"
      center
      :visible="visible"
      :close-on-click-modal="false"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'拖车救援照片配置':'拖车救援照片配置'"
      @update:visible="updateVisible">

      <div class="zhiqianzhihou">
        <!--NPS照片-->
        <span>NPS照片：</span>
        <el-radio v-model="radio" label="1">之前</el-radio>
        <el-radio v-model="radio" label="2">之后</el-radio>
      </div>

      <div class="table_count">
        <div class="xiaoshudaofuji">
          <div class="xiaoshudao"></div>
          <div class="titlestyle">救援地</div>
          <el-button type="primary" size="small" @click="add()">添加</el-button>
        </div>
        <el-table
          :data="List"
          id="table_count"
          row-key="id"
          border
          stripe
          :cellStyle="cellStyle"
          :headerCellStyle="headerCellStyle"
          :rowClassName="rowClassName"
          style="width: 100%">
          <el-table-column
            prop="bianma"
            label="编码">
          </el-table-column>
          <el-table-column
            prop="name"
            label="模板名称">
          </el-table-column>
          <el-table-column
            prop="img"
            label="示例图">
            <!--示例图-->
            <template  slot-scope="scope">
              <div v-viewer class="tabBox_img">
                <img :src="scope.row.img">
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="bi"
            label="必传">
            <!--必传-->
            <template slot-scope="scope">
              <div>
                <el-select v-model="scope.row.bi" clearable placeholder="请选择">
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="huichuan"
            label="回传上游">
            <!--回传上游-->
            <template slot-scope="scope">
              <div>
                <el-select v-model="scope.row.huichuan" clearable placeholder="请选择">
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="shangbianma"
            label="上游编码">
            <!--上游编码-->
            <template slot-scope="scope">
              <div>
                <el-input
                  placeholder="请输入内容"
                  v-model="scope.row.shangbianma"
                  clearable>
                </el-input>
              </div>
            </template>
          </el-table-column>
          <!--<el-table-column-->
          <!--  prop="hege"-->
          <!--  label="是否合格">-->
          <!--  &lt;!&ndash;是否合格&ndash;&gt;-->
          <!--  <template slot-scope="scope">-->
          <!--    <div>-->
          <!--      <el-select v-model="scope.row.hege" clearable placeholder="请选择">-->
          <!--        <el-option value="1" label="是"></el-option>-->
          <!--        <el-option value="0" label="否"></el-option>-->
          <!--      </el-select>-->
          <!--    </div>-->
          <!--  </template>-->
          <!--</el-table-column>-->
          <el-table-column
            prop="weidu"
            label="不合格结算维度">
            <!--结算维度-->
            <template slot-scope="scope">
              <div>
                <el-select v-model="scope.row.weidu" clearable placeholder="请选择">
                  <el-option value="1" label="固定值"></el-option>
                  <el-option value="0" label="百分比"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="zhi"
            label="不合格结算值">
            <!--值-->
            <template slot-scope="scope">
              <div>
                <el-input
                  placeholder="请输入内容"
                  v-model="scope.row.zhi"
                  clearable>
                </el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="yaoqiu"
            width="280"
            label="拍照要求">
            <!--拍照要求-->
            <template slot-scope="scope">
              <div>
                <el-input
                  placeholder="请输入内容"
                  v-model="scope.row.yaoqiu"
                  clearable>
                </el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80">
            <template slot-scope="scope">
              <div v-if="scope.row.name != '救援现场'">
                <i class="handle el-icon-sort"></i>
                <el-popconfirm title="确定执行此操作吗？">
                  <i slot="reference" class="el-icon-delete redicon"></i>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>


        <!-- 数据表格 -->
        <!--<my-table-->
        <!--  id="table_count"-->
        <!--  ref="myTable"-->
        <!--  :columns="columns"-->
        <!--  :tableData="List"-->
        <!--  :cellStyle="cellStyle"-->
        <!--  :headerCellStyle="headerCellStyle"-->
        <!--  :rowClassName="rowClassName"-->
        <!--  :loading="loading1"-->
        <!--&gt;-->

        <!--  &lt;!&ndash;示例图&ndash;&gt;-->
        <!--  <template v-slot:img= "{ scope }">-->
        <!--    <div v-viewer class="tabBox_img">-->
        <!--      <img :src="scope.row.img">-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;必传&ndash;&gt;-->
        <!--  <template v-slot:bi= "{ scope }">-->
        <!--    <div>-->
        <!--      <el-select v-model="scope.row.bi" clearable placeholder="请选择">-->
        <!--        <el-option value="1" label="是"></el-option>-->
        <!--        <el-option value="0" label="否"></el-option>-->
        <!--      </el-select>-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;回传上游&ndash;&gt;-->
        <!--  <template v-slot:huichuan= "{ scope }">-->
        <!--    <div>-->
        <!--      <el-select v-model="scope.row.huichuan" clearable placeholder="请选择">-->
        <!--        <el-option value="1" label="是"></el-option>-->
        <!--        <el-option value="0" label="否"></el-option>-->
        <!--      </el-select>-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;上游编码&ndash;&gt;-->
        <!--  <template v-slot:shangbianma= "{ scope }">-->
        <!--    <div>-->
        <!--      <el-input-->
        <!--        placeholder="请输入内容"-->
        <!--        v-model="scope.row.shangbianma"-->
        <!--        clearable>-->
        <!--      </el-input>-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;是否合格&ndash;&gt;-->
        <!--  <template v-slot:hege= "{ scope }">-->
        <!--    <div>-->
        <!--      <el-select v-model="scope.row.hege" clearable placeholder="请选择">-->
        <!--        <el-option value="1" label="是"></el-option>-->
        <!--        <el-option value="0" label="否"></el-option>-->
        <!--      </el-select>-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;结算维度&ndash;&gt;-->
        <!--  <template v-slot:weidu= "{ scope }">-->
        <!--    <div>-->
        <!--      <el-select v-model="scope.row.weidu" clearable placeholder="请选择">-->
        <!--        <el-option value="1" label="固定值"></el-option>-->
        <!--        <el-option value="0" label="百分比"></el-option>-->
        <!--      </el-select>-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;值&ndash;&gt;-->
        <!--  <template v-slot:zhi= "{ scope }">-->
        <!--    <div>-->
        <!--      <el-input-->
        <!--        placeholder="请输入内容"-->
        <!--        v-model="scope.row.zhi"-->
        <!--        clearable>-->
        <!--      </el-input>-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;拍照要求&ndash;&gt;-->
        <!--  <template v-slot:yaoqiu= "{ scope }">-->
        <!--    <div>-->
        <!--      <el-input-->
        <!--        placeholder="请输入内容"-->
        <!--        v-model="scope.row.yaoqiu"-->
        <!--        clearable>-->
        <!--      </el-input>-->
        <!--    </div>-->
        <!--  </template>-->

        <!--  &lt;!&ndash;操作&ndash;&gt;-->
        <!--  <template v-slot:operationSlot= "{ scope }">-->
        <!--    <i class="el-icon-sort bianju handle"></i>-->
        <!--    <el-popconfirm title="确定执行此操作吗？">-->
        <!--      <i slot="reference" class="el-icon-delete redicon"></i>-->
        <!--    </el-popconfirm>-->
        <!--  </template>-->

        <!--</my-table>-->
      </div>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>


    <el-dialog
      title="选择模板"
      :visible.sync="dialogVisible"
      width="90%"
      center
      :before-close="handleClose">
      <div class="gundongtiao">
        <!--已选择-->
        <div>
          <div>
            <div class="xiaoshudaofuji" style="margin-bottom: 0;">
              <div class="xiaoshudao"></div>
              <div class="titlestyle">已选择</div>
            </div>
          </div>
          <div style="display: flex;align-items: center;flex-flow:row wrap">
            <div class="gediv" v-for="(item,index) in selectList" :key="index">
              <div v-viewer>
                <el-avatar shape="square" :size="130" fit="fill" :src="item.img"></el-avatar>
                <el-tooltip class="item" effect="dark" :content="item.name"placement="top">
                  <span class="gedivdiv">{{item.name}}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>

        <!-- 可选择 -->
        <div class="kexuanze">
          <div>
            <div class="xiaoshudaofuji" style="margin-bottom: 0;">
              <div class="xiaoshudao"></div>
              <div class="titlestyle">可选择</div>
            </div>
          </div>
          <div style="display: flex;align-items: center;flex-flow:row wrap">
            <div class="gediv" v-for="(item,index) in unSelectList" :key="index">
              <div v-viewer>
                <el-avatar shape="square" :size="130" fit="fill" :src="item.img"></el-avatar>
                <el-tooltip class="item" effect="dark" :content="item.name"placement="top">
                  <span class="gedivdiv">
                    <el-checkbox v-model="item.checked">{{item.name}}</el-checkbox>
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div slot="footer">
        <el-button
          @click="dialogVisible = false">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import Sortable from "sortablejs";

export default {
  name: "PhotoconfigurationEdit",
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 父级id
    id: Number,
  },
  components: {
    draggable,
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 验证规则
      rules: {
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 5, max: 20, message: "长度在5 到 20个字符", trigger: "blur" },
          // {
          //   pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          //   message: "请输入正确的手机号码",
          //   trigger: "blur"
          // }
        ],
      },

      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading1: true,
      // 表头数据
      columns: [
        // {
        //   type: "selection",
        //   isShow: true
        // },
        {
          label: "编码",
          // type: "index",
          // width: "70"
          prop: "bianma",
          isShow: true,
        },
        {
          label: "模板名称",
          prop: "name",
          isShow: true,
        },
        {
          label: "示例图",
          prop: "img",
          slot: "img",
          isShow: true,
        },
        {
          label: "必传",
          prop: "bi",
          slot: "bi",
          isShow: true,
        },
        {
          label: "回传上游",
          prop: "huichuan",
          slot: "huichuan",
          isShow: true,
        },
        {
          label: "上游编码",
          prop: "shangbianma",
          slot: "shangbianma",
          isShow: true,
        },
        // {
        //   label: "是否合格",
        //   prop: "hege",
        //   slot: "hege",
        //   isShow: true
        // },
        {
          label: "结算维度",
          prop: "weidu",
          slot: "weidu",
          isShow: true,
        },
        {
          label: "值",
          prop: "zhi",
          slot: "zhi",
          isShow: true,
        },
        {
          label: "拍照要求",
          prop: "yaoqiu",
          slot: "yaoqiu",
          isShow: true,
        },
        {
          label: "操作",
          width: "80",
          slot: "operationSlot",
          isShow: true,
        },
      ],

      //选择模板
      dialogVisible:false,
      //已选择
      selectList: [
        {
          name: "救援现场",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
        },
        {
          name: "救援车车牌照",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
        },
        {
          name: "车架号",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
        },
      ],
      //可选择
      unSelectList: [
        {
          name: "车头45",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "车尾45",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "行驶证照片",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "师傅与车合影",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "平安加官微",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "仪表盘",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "上版照片",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "抢修完成",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "充气前",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "充气过程",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "充气完成",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "吊车救援过程",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },
        {
          name: "下板照片",
          img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
          checked:false
        },

      ],

      //NPS
      radio:'2',

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data);
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    },
  },
  mounted() {
    let data = {
      bianma: "mancar",
      name: "救援现场",
      img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
      bi: "0",
      huichuan: "",
      shangbianma: "",
      // hege:'',
      weidu: "",
      zhi: "",
      yaoqiu: "第一张照片说明; 使用接单链接或者APP 拍照上传",
    };
    this.List.push(data);

    let data1 = {
      bianma: "Rescue_driver_photo",
      name: "救援车车牌照",
      img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
      bi: "0",
      huichuan: "",
      shangbianma: "",
      // hege:'',
      weidu: "",
      zhi: "",
      yaoqiu: "第二张照片说明;上传接单司机绑定的救援车辆照片",
    };
    this.List.push(data1);

    let data2 = {
      bianma: "vin",
      name: "车架号",
      img: "http://shunxingtianxia2023.oss-cn-shenzhen.aliyuncs.com/attach/client/20240312/facilitator-1/d3595202403122142407916.jpg",
      bi: "0",
      huichuan: "",
      shangbianma: "",
      // hege:'',
      weidu: "",
      zhi: "",
      yaoqiu: "第三张照片说明;上传行车证或者是出厂编码，或者",
    };
    this.List.push(data2);

    this.loading1 = false;

    // 拖拽排序
    this.rowDrop();
  },
  methods: {
    // 行拖拽排序
    rowDrop() {
      // 延时获取
      setTimeout(() => {
        const el = document.querySelector(
          "#table_count .el-table__body-wrapper tbody"
        );
        console.log(el);
        console.log(document.querySelector(".handle"));
        let that = this;
        new Sortable(el, {
          animation: 150,
          handle: ".handle",
          ghostClass: "blue-background-class",
          onEnd(evt) {
            console.log(evt);
            if (evt.newIndex == 0) {
              const targetRow = that.List.splice(evt.oldIndex, 1)[0];
              that.List.splice(
                evt.newIndex === 0 ? evt.oldIndex : evt.newIndex,
                0,
                targetRow
              );
              const newList = that.List.slice(0);
              that.List = [];
              const idList = newList.map((item) => item.id);
              that.$nextTick(() => {
                that.List = newList;
              });
              // if (evt.newIndex === 0) {
              //     that.$message.warning('第一个课程不可修改')
              //     return
              // }
            }
          },
        });
      }, 50);
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },

    /* 保存编辑 */
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if (this.isUpdate === false) {
          } else {
          }
        } else {
          return false;
        }
      });
    },


    //点击添加 (选择模板)
    add(){
      this.dialogVisible = true;
    },
    // 选择模板的关闭
    handleClose(){
      this.dialogVisible = false;
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    },
  },
};
</script>

<style scoped lang="scss">
.tabBox_img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
.bianju {
  margin-right: 20px;
  cursor: pointer;
}
.redicon {
  cursor: pointer;
  color: red;
}
.handle {
  margin-right: 15px;
  cursor: pointer;
}

.titlestyle {
  font-size: 18px;
  font-weight: 700;
  color: #202033;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
.xiaoshudao {
  width: 5px;
  height: 20px;
  background: #ff9b05;
  display: inline-block;
}
.xiaoshudaofuji {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.gediv{
  font-size: 0;
  margin: 15px;
  cursor: pointer;
  .gedivdiv{
    font-size: 16px;
    display: block;
    width: 140px;
    background-color: #E4E4E4;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    //文字超出显示...
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 使用省略号表示被隐藏的部分 */
  }
}
.kexuanze{
  margin-top: 20px;
}
.gundongtiao{
  height: 500px;
  overflow-y: auto; /* 只显示垂直滚动条 */
}

.zhiqianzhihou{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
</style>
