<template>
  <!--照片配置 模块-->
  <div>
    <el-card shadow="never">
      <div class="zongti">
        <div class="tongyi">
          <div class="tongyi-t">
            <div class="tongyi-t-font">拖车</div>
            <div>
              <el-button size="mini" type="primary" @click="openEdit(null)">编辑</el-button>
            </div>
          </div>
          <div class="tongyi-b">
            <div class="tongyi-b-font">
              <span style="color: #357EFE;">救援地:</span>
              <span>
                救援现场-mancar; 救援车车牌照-Rescue driver photo车架号-vn仪表盘-dashboardtraier上板照片-uper plate;背车照-traicar;救援现场导航目的地-navigation destinati;
              </span>
            </div>
            <div class="tongyi-b-font">
              <span style="color: #357EFE;">目的地:</span>
              <span>
                目的地门头照-destination; 下板照片-lower plate; 卸车完成-unload; NPS评价-nps;
              </span>
            </div>
            <div class="tongyi-b-font">
              <span style="color: #357EFE;">空驶:</span>
              <span>
                路牌照片-roidsign; 放空地点-emptyaddress;
              </span>
            </div>
            <div class="tongyi-b-font">
              <span style="color: #357EFE;">工单&其他:</span>
              <span>
                车主签名-owner_sign; 接车人签名-meet_sign; 司机签名-driver_sign电子工单-eroadwork; 其它照片-other
              </span>
            </div>
          </div>
        </div>

        <div class="tongyi">
          <div class="tongyi-t">
            <div class="tongyi-t-font">困境</div>
            <div>
              <el-button size="mini" type="primary" @click="openEdit(null)">编辑</el-button>
            </div>
          </div>
          <div class="tongyi-b">
            <div class="tongyi-b-font">
              <span style="color: #357EFE;">救援地:</span>
              <span>
                救援现场-mancar; 救援车车牌照-Rescue driver photo车架号-vn仪表盘-dashboardtraier上板照片-uper plate;背车照-traicar;救援现场导航目的地-navigation destinati;
              </span>
            </div>
            <div class="tongyi-b-font">
              <span style="color: #357EFE;">空驶:</span>
              <span>
                路牌照片-roidsign; 放空地点-emptyaddress;
              </span>
            </div>
            <div class="tongyi-b-font">
              <span style="color: #357EFE;">工单&其他:</span>
              <span>
                车主签名-owner_sign; 接车人签名-meet_sign; 司机签名-driver_sign电子工单-eroadwork; 其它照片-other
              </span>
            </div>
          </div>
        </div>


      </div>
    </el-card>

    <photoconfiguration-edit v-if="showEdit === true" :data="current" :id="id" :dict="dict" :visible.sync="showEdit" @done=""/>

  </div>
</template>

<script>
// 引入照片配置编辑
import PhotoconfigurationEdit from './components/photoconfiguration-edit.vue'
import AddressbookEdit from "@/views/custom/details/components/components/addressbook-edit.vue";

export default {
  components:{
    AddressbookEdit,
    PhotoconfigurationEdit
  },
  props: {
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
    // 字典数据
    dict:Object,
  },
  data(){
    return{
      // 更细签约数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,

      pid:0,

    }
  },

  mounted() {

  },

  methods:{
    // 点击更新签约按钮
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },


  }

}
</script>

<style lang="scss" scoped>
  .zongti{

  }
  .tongyi{
    background-color: #FAFAFA;
    margin-bottom: 20px;
  }

  .tongyi-t{
    padding: 20px;
    border-bottom: 1px solid #E9EDF5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tongyi-t-font{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .tongyi-b{
    padding: 20px;
    .tongyi-b-font{
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
</style>
